export const seo = {
  url: 'csr',
  title: {
    en: 'CSR - organization activities Omida Logistics Sp. z o.o.',
    es: 'RSC - organización actividades Omida Logistics Sp. z o.o.',
    ro: 'CSR - activitati de organizare Omida Logistics Sp. z o.o.',
  },
  desc: {
    en: 'Omida has been supporting social and cultural activities for many years. Discover the areas supported by one of the most recognizable TFL organizations - Omida Logistics Sp. z o.o.',
    es: 'Omida lleva muchos años apoyando actividades sociales y culturales. Descubra las áreas respaldadas por una de las organizaciones de TFL más reconocidas: Omida Logistics Sp. z o.o.',
    ro: 'Omida susține activități sociale și culturale de mulți ani. Descoperiți zonele susținute de una dintre cele mai recunoscute organizații TFL - Omida Logistics Sp. z o.o.',
  },
  keywords: ['omida', 'logistics', 'transport'],
}

export const intro = {
  title: {
    en: 'CSR',
    es: 'RSC',
    ro: 'CSR',
  },
  desc: {
    en: 'Omida has been supporting social and cultural activities for many years. Discover the areas supported by one of the most recognizable TFL organizations - Omida Logistics Sp. z o.o.',
    es: 'Omida lleva muchos años apoyando actividades sociales y culturales. Descubra las áreas respaldadas por una de las organizaciones de TFL más reconocidas: Omida Logistics Sp. z o.o.',
    ro: 'Omida susține activități sociale și culturale de mulți ani. Descoperiți zonele susținute de una dintre cele mai recunoscute organizații TFL - Omida Logistics Sp. z o.o.',
  },
  button: {
    text: {
      en: 'Read more',
      es: 'Leer más',
      ro: 'Citiți mai mult',
    },
    action: 'SCROLL',
  },
}

export const main = {
  body: [
    {
      headline: {
        en: 'What CSR - Corporate Social Responsibility means?',
        es: '¿Qué significa RSC - Responsabilidad Social Corporativa?',
        ro: 'Ce înseamnă CSR - Responsabilitate Socială Corporativă?',
      },
      texts: [
        {
          en: `<span><strong>Social responsibility</strong> is a key factor in Omida's strategy. Therefore, in our daily work and strategic plans, we want to combine the achievement of business goals with activities that respond to <strong>the diverse needs of everyone</strong>: employees, customers, business partners and shareholders.
      </span>`,
          es: `<span><strong>La responsabilidad social</strong> es un factor clave en la estrategia de Omida. Por ello, en nuestro trabajo diario y planes estratégicos, queremos combinar la consecución de los objetivos empresariales con actividades que respondan a <strong>las diversas necesidades de todos</strong>: empleados, clientes, socios comerciales y accionistas.
          </span>`,
          ro: `<span><strong>Responsabilitatea socială</strong> este un factor cheie în strategia Omida. Prin urmare, în munca noastră de zi cu zi și în planurile strategice, dorim să combinăm atingerea obiectivelor de afaceri cu activități care să răspundă la <strong>nevoile diverse ale tuturor</strong>: angajați, clienți, parteneri de afaceri și acționari.
          </span>`,
        },
      ],
    },
    {
      headline: {
        en: 'CSR activities of Omida Logistics Sp. z o.o.',
        es: 'Actividades de RSC de Omida Logistics Sp. z o.o.',
        ro: 'Activitățile de CSR ale Omida Logistics Sp. z o.o.',
      },
      texts: [
        {
          en: `<span>
          Corporate social responsibility is a business approach that contributes to sustainable development by providing economic, social and environmental benefits for all stakeholders. Corporate social responsibility, or CSR for short, covers various sectors such as education, health, environmental sustainability, community development and rural development. Details of the CSR projects carried out by Omida Logistics Sp. z o.o. are given below. In recent years.
      </span>`,
          es: `<span>La responsabilidad social empresarial es un enfoque empresarial que contribuye al desarrollo sostenible proporcionando beneficios económicos, sociales y ambientales para todos los grupos de interés. La responsabilidad social empresarial, o RSE para abreviar, cubre varios sectores como la educación, la salud, la sostenibilidad ambiental, el desarrollo comunitario y el desarrollo rural. A continuación se detallan los proyectos de RSC llevados a cabo por Omida Logistics Sp. z o.o. En años recientes.
      </span>`,
          ro: `<span>Responsabilitatea socială corporativă este o abordare de afaceri care contribuie la dezvoltarea durabilă oferind beneficii economice, sociale și de mediu pentru toate părțile interesate. Responsabilitatea socială corporativă, sau CSR pe scurt, acoperă diverse sectoare precum educația, sănătatea, durabilitatea mediului, dezvoltarea comunității și dezvoltarea rurală. Detalii despre proiectele CSR derulate de Omida Logistics Sp. z o.o. sunt date mai jos. În ultimii ani.
      </span>`,
        },
      ],
    },
  ],
}

export const features = [
  {
    id: 'objective',
    icon: 'CSR_objective',
    subtitle: {
      en: 'Social',
      es: 'Sociales',
      ro: 'Social',
    },
    title: {
      en: 'Goals',
      es: 'Objetivos',
      ro: 'Goluri',
    },
    desc: {
      en: 'Realization of social goals impossible to achieve without business support',
      es: 'Realización de objetivos sociales imposibles de lograr sin el apoyo empresarial',
      ro: 'Realizarea unor obiective sociale imposibil de realizat fără sprijinul afacerilor',
    },
  },
  {
    id: 'social',
    icon: 'CSR_social',
    subtitle: {
      en: 'Social',
      es: 'Social',
      ro: 'Social',
    },
    title: {
      en: 'Support',
      es: 'Soporte',
      ro: 'A susține',
    },
    desc: {
      en: 'High quality of social facilities',
      es: 'Alta calidad de las instalaciones sociales',
      ro: 'Facilități sociale de înaltă calitate',
    },
  },
  {
    id: 'support',
    icon: 'CSR_support',
    subtitle: {
      en: 'Support for',
      es: 'Apoyo a la',
      ro: 'Suport pentru',
    },
    title: {
      en: 'Culture',
      es: 'Cultura',
      ro: 'Cultură',
    },
    desc: {
      en: 'Cultural support for local communities',
      es: 'Apoyo cultural a las comunidades locales',
      ro: 'Sprijin cultural pentru comunitățile locale',
    },
  },
  {
    id: 'charitywork',
    icon: 'CSR_charitywork',
    subtitle: {
      en: 'Work for',
      es: 'Trabajar por',
      ro: 'Lucrează pentru',
    },
    title: {
      en: 'Charity',
      es: 'Caridad',
      ro: 'Caritate',
    },
    desc: {
      en: 'Helping those in need disinterestedly',
      es: 'Ayudar a los necesitados desinteresadamente',
      ro: '',
    },
  },
  {
    id: 'development',
    icon: 'CSR_development',
    subtitle: {
      en: 'Professional Development and',
      es: 'Desarrollo Profesional y',
      ro: '',
    },
    title: {
      en: 'Training',
      es: 'Formación',
      ro: '',
    },
    desc: {
      en: 'Constant professional development thanks to the availability of training',
      es: 'Desarrollo profesional constante gracias a la disponibilidad de formación',
      ro: 'Dezvoltare profesională constantă datorită disponibilității pregătirii',
    },
  },
  {
    id: 'education',
    icon: 'CSR_education',
    subtitle: {
      en: 'Youth Development and',
      es: 'Desarrollo y Juvenil',
      ro: 'Dezvoltarea Tineretului și',
    },
    title: {
      en: 'Education',
      es: 'Educación',
      ro: 'Educaţie',
    },
    desc: {
      en: 'Involvement in education and youth development',
      es: 'Participación en la educación y el desarrollo de la juventud',
      ro: 'Implicarea în educație și dezvoltarea tinerilor',
    },
  },
  {
    id: 'ecology',
    icon: 'CSR_ecology',
    subtitle: {
      en: 'Care for the',
      es: 'Cuidado del medio',
      ro: 'Aveți grijă de',
    },
    title: {
      en: 'Environment',
      es: 'Ambiente',
      ro: 'Mediu inconjurator',
    },
    desc: {
      en: 'Limiting the negative impact of enterprises on the environment',
      es: 'Limitar el impacto negativo de las empresas en el medio ambiente',
      ro: 'Limitarea impactului negativ al întreprinderilor asupra mediului',
    },
  },
  {
    id: 'waste',
    icon: 'CSR_waste',
    subtitle: {
      en: 'Managing the',
      es: 'Gestión de',
      ro: 'Gestionarea',
    },
    title: {
      en: 'Waste',
      es: 'Residuos',
      ro: 'Deşeuri',
    },
    desc: {
      en: 'Rational management of natural resources and waste',
      es: 'Gestión racional de los recursos naturales y los residuos',
      ro: 'Gestionarea rațională a resurselor naturale și a deșeurilor',
    },
  },
  {
    id: 'ecology2',
    icon: 'CSR_ecology2',
    subtitle: {
      en: 'Activity',
      es: 'Actividad',
      ro: 'Activitate',
    },
    title: {
      en: 'Pro-ecological',
      es: 'Pro-ecológica',
      ro: 'Pro-ecologic',
    },
    desc: {
      en: 'Involving business partners in the chain of environmental responsibility and initiating joint pro-ecological activities',
      es: 'Involucrar a los socios comerciales en la cadena de responsabilidad ambiental e iniciar actividades pro-ecológicas conjuntas',
      ro: 'Implicarea partenerilor de afaceri în lanțul de responsabilitate ecologică și inițierea de activități comune pro-ecologice',
    },
  },
]
